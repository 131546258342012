import React, { useEffect, useState } from 'react';
import RequestImg from '../../../assets/image/requestGame.png';
import Loading from '../../../components/Common/Loading';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApiHandler } from '../../../helper/ApiHandler';
import axios from 'axios';

const RequestRedeem = () => {
    const [amount, setAmount] = useState('');
    const [username, setUsername] = useState('');
    const [withdrawalMethod, setWithdrawalMethod] = useState('');
    const [cryptoUserId, setCryptoUserId] = useState('');
    const [cashAppFile, setCashAppFile] = useState(null);
    const [paypalEmail, setPaypalEmail] = useState('');
    const [error, setError] = useState('');
    const [selectedGame, setSelectedGame] = useState(null);
    const [updateGame, setUpdateGame] = useState([]);
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const [fetchError, setFetchError] = useState('');
    const { activeLevel, token, user } = useSelector(state => state.auth);

const [withdrawableAmount, setWithdrawableAmount] = useState(null); // Added
const [withdrawableMessage, setWithdrawableMessage] = useState(''); // Added

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!allowedFileTypes.includes(file.type)) {
                setError('Only PNG, JPG, and JPEG formats are allowed.');
                return;
            }

            const formData = new FormData();
            formData.append('cashAppScreenshot', file);

            try {
                const response = await axios.post(
                    'https://api.luckycharmsweep.com/api/v2/dfjfngjn/getCashAppImgForRedeem.php',
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                const result = response.data.status;
                if (result.code === 1) {
                    setCashAppFile(response.data.data);
                    setError('');
                } else {
                    setError(result.message || 'Failed to upload screenshot.');
                    toast.error(result.message || 'Failed to upload screenshot.');
                }
            } catch (error) {
                setError('Error uploading screenshot. Please try again.');
                toast.error('Error uploading screenshot. Please try again.');
            }
        }
    };

    const handleGameChange = (event) => {
        const selectedGameId = event.target.value;
        const selectedGame = updateGame.find(game => game.game_id === selectedGameId);
        setSelectedGame(selectedGame);
        setDisabled(false);

        if (selectedGame) {
            setUsername(selectedGame.username);
        }
    };
    const handleCheckWithdrawable = async () => {
        setWithdrawableAmount(null);
        setWithdrawableMessage('');
        if (!amount || parseFloat(amount) < 10) {
            setError('Amount must be at least $10.');
            return;
        }
        if (!selectedGame) {
            setError('Please select a game.');
            return;
        }
        setError('');
    
        try {
            // Ensure `ApiHandler` supports async calls and returns a Promise
            const response = await ApiHandler('/get_withdrawable.php', 'POST', { amount, game_id: selectedGame.game_id }, token, dispatch, navigate);
            if (response.data.status.code === 1) {
                setWithdrawableAmount(response.data.data.withdrawable_amount);
                setWithdrawableMessage(response.data.message);
                toast.success('Withdrawable amount calculated successfully.');
            } else {
                setWithdrawableAmount(null);
                setWithdrawableMessage('');
                toast.error(response.data.status.message || 'Failed to calculate withdrawable amount.');
            }
        } catch (error) {
            setWithdrawableAmount(null);
            setWithdrawableMessage('');
            toast.error('Error calculating withdrawable amount. Please try again.');
        }
    };
    const fetchPlatforms = async () => {
        try {
            const response = await ApiHandler('/my_game_list.php', 'GET', undefined, token, dispatch, navigate);
            if (response.data.status.code === 1) {
                const data = response.data.data;
                setUpdateGame(data);
            } else {
                setUpdateGame([]);
                setFetchError('Failed to fetch game data');
            }
        } catch (error) {
            setUpdateGame([]);
            setFetchError('Error fetching game data.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPlatforms();
    }, []);
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!withdrawableAmount) {
            setError('Please check your withdrawable status first.');
            return;
        }
  // Validate minimum amounts for crypto methods
  if (withdrawalMethod === 'Bitcoin' && parseFloat(amount) < 50) {
    setError('Minimum amount for Bitcoin withdrawal is $50.');
    return;
}

if (['Litecoin', 'Dogecoin'].includes(withdrawalMethod) && parseFloat(amount) < 30) {
    setError('Minimum amount for Litecoin and Dogecoin withdrawal is $30.');
    return;
}
        if (withdrawalMethod === 'PayPal' && !paypalEmail) {
            setError('PayPal email is required.');
            return;
        }

        if (
            parseFloat(amount) < 10 ||
            parseFloat(amount) > parseFloat(activeLevel?.withdrawal_limit || 100)
        ) {
            setError(`Amount must be between $10 and $${activeLevel?.withdrawal_limit || 100}.`);
            return;
        }

        if (['Bitcoin', 'Dogecoin', 'Litecoin'].includes(withdrawalMethod) && !cryptoUserId) {
            setError('Crypto User ID is required.');
            return;
        }

        if (withdrawalMethod === 'CashApp' && !cashAppFile) {
            setError('Please upload a file for CashApp.');
            return;
        }

        const body = {
            game_id: selectedGame?.game_id,
            email: user.email,
            phone: user.phone,
            game_name: selectedGame?.game_name,
            game_username: username,
            amount: amount,
            withdrawal_method: withdrawalMethod,
            crypto_user_id:
                withdrawalMethod === 'CashApp'
                    ? cashAppFile
                    : withdrawalMethod === 'PayPal'
                    ? paypalEmail
                    : cryptoUserId,
        };

        try {
            const response = await ApiHandler('/redeem_request_for_game.php', 'POST', body, token, dispatch, navigate);

            if (response.data.status.code === 1) {
                toast.success('Your request has been successfully submitted and will be processed within an hour.');
                setError('');
                setWithdrawalMethod('');
                setCryptoUserId('');
                setCashAppFile(null);
                setSelectedGame(null);
                setAmount('');
                setUsername('');
                navigate('/user/withdrawals');
            } else {
                toast.error(response.data.status.message);
            }
        } catch (error) {
            setError('Error submitting redeem request. Please try again.');
            toast.error('Error submitting redeem request. Please try again.');
        }
    };

    const validGames = updateGame.filter(game => game.username && game.password);

    if (loading) return <div className="text-white text-center">Loading games...</div>;

    return (
        <div className="flex justify-center min-h-screen bg-[#0e0e0e]">
            <div className="bg-[#222222] text-white p-8 rounded-lg shadow-md w-full max-w-xl">
                <h2 className="text-2xl font-bold text-center text-[#FFDD15] underline mb-4">Request Redeem</h2>

                <div className="mb-6 flex justify-center">
                    <img src={RequestImg} alt="Deposit Illustration" className="w-44 rounded-lg" />
                </div>

                <div className="mb-4">
                    <label className="block text-white mb-2">Select a Game</label>
                    <select
                        className="w-full px-2 py-3 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-black"
                        onChange={handleGameChange}
                        defaultValue=""
                    >
                        <option value="" disabled>
                            {updateGame.length > 0 && validGames.length > 0 ? 'Select a Game' : 'No games available'}
                        </option>
                        {validGames.map(game => (
                            <option key={game.game_id} value={game.game_id}>
                                {game.game_name}
                            </option>
                        ))}
                    </select>
                </div>

                {selectedGame && (
                    <div className="overflow-x-auto mb-4">
                        <table className="w-full text-left text-white border border-gray-700 bg-black">
                            <thead>
                                <tr className="bg-black">
                                    <th className="px-4 py-2 text-white/50 font-normal">Logo</th>
                                    <th className="px-4 py-2 text-white/50 font-normal">Name</th>
                                    <th className="px-4 py-2 text-white/50 font-normal">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="px-4 py-2">
                                        <img
                                            src={`${selectedGame.game_image}`}
                                            alt={selectedGame.game_name}
                                            className="w-20 h-20 rounded-lg"
                                        />
                                    </td>
                                    <td className="px-4 py-2">{selectedGame.game_name}</td>
                                    <td className="px-4 py-2 text-[#01D370]">${selectedGame.game_price}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                <div className="mb-4">
                    <label className="block text-white mb-2">Game Username</label>
                    <input
                        type="text"
                        value={username}
                        readOnly
                        placeholder="Game username"
                        className="w-full p-2 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-black"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-white mb-2">Amount ($)</label>
                    <input
                        type="number"
                        value={amount}
                        onChange={e => setAmount(e.target.value)}
                        placeholder="Enter an amount"
                        className="w-full p-2 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-black"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-white mb-2">Withdrawal Method</label>
                    <select
                        value={withdrawalMethod}
                        onChange={e => setWithdrawalMethod(e.target.value)}
                        className="w-full p-2 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-black"
                    >
                        <option value="">Select a method</option>
                        <option value="Bitcoin">Bitcoin</option>
                        <option value="Dogecoin">Dogecoin</option>
                        <option value="Litecoin">Litecoin</option>
                        <option value="CashApp">CashApp</option>
                        <option value="PayPal">PayPal</option>
                    </select>
                </div>

                {['Bitcoin', 'Dogecoin', 'Litecoin'].includes(withdrawalMethod) && (
                    <div className="mb-4">
                        <label className="block text-white mb-2">Crypto User ID</label>
                        <input
                            type="text"
                            value={cryptoUserId}
                            onChange={e => setCryptoUserId(e.target.value)}
                            placeholder="Enter your crypto user ID"
                            className="w-full py-3 px-2 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-black"
                        />
                    </div>
                )}

                {withdrawalMethod === 'CashApp' && (
                    <div className="mb-4">
                        <label className="block text-white mb-2">Upload CashApp Screenshot</label>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="w-full p-2 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-black"
                        />
                    </div>
                )}

                {withdrawalMethod === 'PayPal' && (
                    <div className="mb-4">
                        <label className="block text-white mb-2">PayPal Email</label>
                        <input
                            type="email"
                            value={paypalEmail}
                            onChange={e => setPaypalEmail(e.target.value)}
                            placeholder="Enter your PayPal email"
                            className="w-full p-2 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-black"
                        />
                    </div>
                )}

                {error && <div className="text-red-500 text-sm mb-4">{error}</div>}
                {fetchError && <div className="text-red-500 text-sm mb-4">{fetchError}</div>}
                <button
                    onClick={handleCheckWithdrawable}
                    className="w-full py-2 rounded-md font-bold text-lg mb-4 bg-yellow-500 text-white"
                >
                    Check Withdrawable Amount
                </button>

                {withdrawableMessage && <div className="text-green-500 mb-4">{withdrawableMessage}</div>}
                {withdrawableAmount !== null && (
                    <div className="text-white mb-4">
                        Withdrawable Amount: <span className="text-green-400">${withdrawableAmount.toFixed(2)}</span>
                    </div>
                )}
                <button
                    onClick={handleSubmit}
                    type="submit"
                    className={`w-full py-2 rounded-md font-bold text-lg mt-7 ${
                        !amount || !withdrawalMethod || !username || disabled
                            ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
                            : 'bg-[#FFDD15] text-black'
                    }`}
                    disabled={!amount || !withdrawalMethod || !withdrawableAmount || !username || disabled}
                >
                    Submit Request
                </button>
            </div>
        </div>
    );
};

export default RequestRedeem;
