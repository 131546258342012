import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import downloadIcon from "../../assets/image/download.png";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../../components/Common/Loading";
import { resetGamesState } from "../../redux/slice/gamesSlice";
import { ApiHandler } from "../../helper/ApiHandler";
import axios from "axios";
import { FaClipboard } from "react-icons/fa"; // Clipboard icon from react-icons
const CashAppPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const {
    directPassPage = false,
    cartDataState = [],
    totalAmountState = '',
    method = "",
    promocodeDiscount = '',
    discountamount = '',
    totalDepositBonusAmount = '',
    weeklyChallengeBonusAmount = '',
    finalTotal = '',
    promoCode = "",
    userData = "",
    proof_image = "",
    typeApi = false,
    promoCodeData = "",
    BonusLevelAmount = "",
    registrationBonusPercentage = '',
    totalAmount = '',
  } = location.state || {};

  const [cashAppFile, setCashAppFile] = useState(null); // For CashApp file upload
  const [error, setError] = useState("");
  const [cashAppPayLink, setCashAppPayLink] = useState(""); // CashApp pay link from API

  // Handle file selection
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setError("");
    if (file) {
      // Validate file type and size
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"]; // Allowed file types

      if (!allowedFileTypes.includes(file.type)) {
        setError("Only PNG, JPG, and JPEG formats are allowed.");
        return;
      }

      // Now call the API to upload the file
      const formData = new FormData();
      formData.append("cashAppScreenshot", file); // Append file to the form data

      try {
        const response = await axios.post('https://api.luckycharmsweep.com/api/v2/dfjfngjn/getCashAppImgForRedeem.php', formData, {
          headers: {
            'Authorization': `Bearer ${token}`, // Assuming you pass token this way
            'Content-Type': 'multipart/form-data', // Let the browser automatically set boundary
          }
        });

        const result = response.data.status;

        // Handle specific status codes
        if (response.status === 401 || response.status === 400 || result.code === 401) {
          toast.error(result.message || "Unauthorized. Please log in again.");
          navigate("/login"); // Redirect to login on unauthorized access
        } else if (result.code === 404) {
          toast.error(result.message || "File not found. Please try again.");
          throw new Error(result.message);
        } else if (result.code === 500) {
          toast.error("Something went wrong. Please try again.");
          navigate("/500"); // Redirect to error page for server errors
        }

        // Success: Handle file upload success
        if (result.code === 1) {
          setCashAppFile(response.data.data); // Update the state with uploaded file data
        } else {
          setError('Failed to upload screenshot. Please try again.');
          toast.error(result.message || 'Failed to upload screenshot.');
        }
      } catch (error) {
        setError('Error uploading screenshot. Please try again.');
        toast.error('Error uploading screenshot. Please try again.');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const typeTrue = {
      amount: totalAmountState ? parseFloat(totalAmountState) : '',
      email: userData.email,
      promocode: promoCode,
      promocodeDiscount: promocodeDiscount ? parseFloat(promocodeDiscount) : '',
      discountamount: discountamount ? parseFloat(discountamount) : '',
      totalamount: finalTotal ? parseFloat(finalTotal) : '',
      payment_type: method,
      cashAppScreenshot: cashAppFile,
    };


    if (typeApi) {
      try {
        setLoading(true);
        dispatch(resetGamesState());

        const response = await ApiHandler('/get_wallet_recharge_detail.php', 'POST', typeTrue, token, dispatch, navigate);
        if (response?.data?.status.code === 0) {
          toast.error(response?.data?.status.message);
          navigate("/dashboard");
          setLoading(false);
        } else {
          toast.success("Deposit request submitted successfully! Your funds will be added shortly.");
          navigate("/dashboard");
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        dispatch(resetGamesState());

        const amount = finalTotal;
        const totalAmount = totalAmountState;

        const response = await ApiHandler('/deposit_game.php', 'POST', {
          email: userData.email,
          phone: userData.phone,
          amount: amount ? parseFloat(amount) : '',
          promocode: promoCode,
          promocodeDiscount: promoCodeData.promoCodePercentage ? parseFloat(promoCodeData.promoCodePercentage) : '',
          discountamount: promoCodeData.promoCodeAmount ? parseFloat(promoCodeData.promoCodeAmount) : '',
          totalamount: totalAmount ? parseFloat(totalAmount) : '',
          payment_type: method,
          weekly_challenge_bonus: weeklyChallengeBonusAmount ? parseFloat(weeklyChallengeBonusAmount) : '',
          game_detail: cartDataState.map((game) => ({
            game_name: game.game_name ? game.game_name : '',
            game_id: game.id ? game.id : '',
            price: game.game_price ? parseFloat(game.game_price) : '',
            quantity: game.quantity ? parseFloat(game.quantity) : '',
            total: parseFloat(game.quantity * (game.game_price || game.price)),
            deposit_bonus: game.bonus ? parseFloat(game.bonus) : '', // Always copy the bonus field
            platformsID: game.platforms_id ? game.platforms_id : '',
          })),
          account_level_bonus: BonusLevelAmount ? parseFloat(BonusLevelAmount) : '',
          totalDepositBonusAmount: totalDepositBonusAmount ? parseFloat(totalDepositBonusAmount) : '',
          registration_bonus_for_new_users: registrationBonusPercentage ? parseFloat(registrationBonusPercentage) : '',
          cashAppScreenshot: cashAppFile ? cashAppFile : ''
        }, token, dispatch, navigate);

        if (response.data.status.code === 1) {
          if (response.data.data.checkoutLink) {
            setLoading(false);
            window.location.href = response.data.data.checkoutLink
          } else {
            toast.success("Deposit request submitted successfully! Your funds will be added shortly.");
            navigate('/user/deposits');
            setLoading(false);
          }
        } else {
          toast.error(response.data.status.message)
          setLoading(false);
        }
      } catch (error) {
        console.error("API call error:", error);
        setLoading(false);
      }
    }
  };


  useEffect(() => {
    if (!location.state?.isCheckoutAllowed) {
      // If no state or invalid state, redirect to the home page
      navigate('/cart');
    } else {
      const currentState = window.history.state;
      const updatedState = {
        ...currentState,
        usr: {
          ...currentState.usr,
          isCheckoutAllowed: false
        }
      };

      // Replace the current history state with the updated state
      window.history.replaceState(updatedState, document.title);
    }
  }, [location, navigate]);

  useEffect(() => {
    const fetchCashAppLink = async () => {
      try {
        setLoading(true);
        const response = await ApiHandler("/get_cashapp_link.php", "GET");
  
        if (response?.data?.status?.code === 1) {
          const cashAppUsername = response.data.data.cashapplink; // Correct key for CashApp username
          const formattedFinalTotal = parseFloat(finalTotal || 0).toFixed(2); // Ensure the amount is properly formatted
          const timestamp = Date.now();

          // Construct the label with username and game names
          const userName = userData.phone|| "User"; // Use username or a fallback
          const gameNames = cartDataState
            .map((game) => game.game_name)
            .join(", "); // Join game names with commas
  
            const label = `${userName}-${timestamp}`;
  
          // Generate the CashApp payment link
          const link = `https://cash.app/${cashAppUsername}?amount=${formattedFinalTotal}&label=${encodeURIComponent(
            label
          )}`;
          
          setCashAppPayLink(link); // Set the generated link
        } else {
          toast.error("Failed to fetch CashApp pay link.");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching CashApp link:", error);
        setLoading(false);
      }
    };
  
    fetchCashAppLink();
  }, [finalTotal, userData, cartDataState]); // Ensure dependencies are included
  
  if (!token) {
    navigate('/login');
    return;
  }

  
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#0e0e0e] p-4 sm:p-8 lgs:pt-36">
      <div className="w-full max-w-md sm:max-w-lg bg-[#1F2937] p-4 sm:p-6 rounded-lg shadow-lg border border-white/50">
        <div className="flex justify-center mb-6">
          <button className="bg-[#FF736D] text-white py-2 px-4 rounded-full font-bold text-sm sm:text-base">
            Complete your payment via Cashapp
          </button>
        </div>

        {directPassPage ? (
          <>
            <div>
              <div className="text-white mb-4">
                <h2 className="font-bold text-lg">Order Details</h2>
                <hr className="border-white/5 border my-3" />
                {userData?.first_name ? (
                  <div className="mb-2 flex justify-start gap-3 font-bold">
                    <p>Name:</p>
                    <p>{userData?.first_name}</p>
                  </div>
                ) : ""}
                {userData?.email ? (
                  <div className="mb-2 flex justify-start gap-3 font-bold">
                    <p>Email:</p>
                    <p>{userData?.email}</p>
                  </div>
                ) : ""}
                {userData?.first_name ? (
                  <div className="mb-2 flex justify-start gap-3 font-bold">
                    <p>Phone:</p>
                    <p>{userData?.phone}</p>
                  </div>
                ) : ""}
              </div>

              <div className="text-white mt-8">
                <h2 className="font-bold text-lg">Bonuses & Discounts</h2>
                {totalAmount && totalAmount > 0 ? (
                  <div className="mb-2 flex justify-between font-bold">
                    <p>Amount</p>
                    <p>${totalAmount ? parseFloat(totalAmount).toFixed(2) : ''}</p>
                  </div>
                ) : ""}
                <hr className="border-white/5 border my-3" />
                {!promoCode ? (
                  <div className="mb-2 flex justify-between items-center font-bold">
                    <p>Total Amount</p>
                    <span className="text-sm text-white px-2 py-1 rounded-md">
                      ${finalTotal ? parseFloat(finalTotal).toFixed(2) : ''}
                    </span>
                  </div>
                ) : ""}
                {promoCode ? (
                  <div className="mb-2 flex justify-between items-center font-bold">
                    <p>Amount</p>
                    <span className="text-sm text-white  px-2 py-1 rounded-md">
                      ${totalAmountState ? parseFloat(totalAmountState).toFixed(2) : ''}
                    </span>
                  </div>
                ) : ""}
                {promoCode ? (
                  <div className="mb-2 flex justify-between items-center font-bold">
                    <p>PromoCode Applied</p>
                    <span className="text-sm text-white bg-blue-500 px-2 py-1 rounded-md">
                      {promoCode}
                    </span>
                  </div>
                ) : ""}
                {promoCode ? (
                  <div className="mb-2 flex justify-between items-center font-bold">
                    <p>PromoCode total discount</p>
                    <span className="text-sm text-white  px-2 py-1 rounded-md">
                      {promocodeDiscount ? parseFloat(promocodeDiscount).toFixed(2) : ''}%
                    </span>
                  </div>
                ) : ""}
                {promoCode ? (
                  <div className="mb-2 flex justify-between items-center font-bold">
                    <p>PromoCode total discount amount</p>
                    <span className="text-sm text-white  px-2 py-1 rounded-md">
                      ${discountamount ? parseFloat(discountamount).toFixed(2) : ''}
                    </span>
                  </div>
                ) : ""}

                {finalTotal ? (
                  <div className="mt-4 flex justify-between">
                    <p className="font-bold">Final Amount</p>
                    <p>${finalTotal ? parseFloat(finalTotal).toFixed(2) : ''}</p>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="text-white mb-4">
                <h2 className="font-bold text-lg">Order Details</h2>
                <hr className="border-white/5 border my-3" />
                {userData?.first_name ? (
                  <div className="mb-2 flex justify-start gap-3 font-bold">
                    <p>Name:</p>
                    <p>{userData?.first_name}</p>
                  </div>
                ) : ""}
                {userData?.email ? (
                  <div className="mb-2 flex justify-start gap-3 font-bold">
                    <p>Email:</p>
                    <p>{userData?.email}</p>
                  </div>
                ) : ""}
                {userData?.first_name ? (
                  <div className="mb-2 flex justify-start gap-3 font-bold">
                    <p>Phone:</p>
                    <p>{userData?.phone}</p>
                  </div>
                ) : ""}
              </div>
              <div className="text-white mt-8">
                <h2 className="font-bold text-lg">Bonuses & Discounts</h2>
                <hr className="border-white/5 border my-3" />
                {registrationBonusPercentage && registrationBonusPercentage > 0 ? (
                  <div className="mb-2 flex justify-between font-bold">
                    <p>User First time Deposit Add Bounes</p>
                    <p>${registrationBonusPercentage ? parseFloat(registrationBonusPercentage).toFixed(2) : ''}</p>
                  </div>
                ) : ""}
                {totalDepositBonusAmount ? (
                  <div className="mb-2 flex justify-between font-bold">
                    <p>Deposit Bonus</p>
                    <p>${totalDepositBonusAmount ? parseFloat(totalDepositBonusAmount).toFixed(2) : ''}</p>
                  </div>
                ) : ""}
                {weeklyChallengeBonusAmount ? (
                  <div className="mb-2 flex justify-between font-bold">
                    <p>Weekly Challenge Bonus</p>
                    <p>${weeklyChallengeBonusAmount ? parseFloat(weeklyChallengeBonusAmount).toFixed(2) : ''}</p>
                  </div>
                ) : ""}
                {BonusLevelAmount ? (
                  <div className="mb-2 flex justify-between font-bold">
                    <p>Bonus Level</p>
                    <p>${BonusLevelAmount ? parseFloat(BonusLevelAmount).toFixed(2) : ''}</p>
                  </div>
                ) : ""}
                {promoCode ? (
                  <div className="mb-2 flex justify-between items-center font-bold">
                    <p>Promo Code</p>
                    <span className="text-sm text-white bg-blue-500 px-2 py-1 rounded-md">
                      {promoCode}
                    </span>
                  </div>
                ) : ""}

                {promocodeDiscount ? (
                  <div className="mb-2 flex justify-between font-bold">
                    <p>Promo Code Discount</p>
                    <p>{promocodeDiscount ? `${parseFloat(promocodeDiscount).toFixed(2)}%` : ''}</p>
                  </div>
                ) : null}
                {promoCode ? (
                  <div className="mb-2 flex justify-between items-center font-bold">
                    <p>PromoCode total discount amount</p>
                    <p>${discountamount ? parseFloat(discountamount).toFixed(2) : ''}</p>
                  </div>
                ) : ""}
                {totalAmountState ? (
                  <div className="mb-2 flex justify-between font-bold">
                    <p>Original Total</p>
                    <p>${totalAmountState ? parseFloat(totalAmountState).toFixed(2) : ''}</p>
                  </div>
                ) : ""}

                <hr className="border-white/5 border my-5" />

                {finalTotal ? (
                  <div className="mt-4 flex justify-between">
                    <p className="font-bold">Total Amount</p>
                    <p>${finalTotal ? parseFloat(finalTotal).toFixed(2) : ''}</p>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col items-center mb-4">
          <img
            src={proof_image}
            alt="CashApp Barcode"
            className="w-48 h-48 sm:w-64 sm:h-64 object-contain mb-4"
          />
        </div>
        {cashAppPayLink && (
  <div className="mb-4 text-center">
    <a
      href={cashAppPayLink}
      target="_blank"
      rel="noopener noreferrer"
      className="bg-[#FFDD15] text-black py-2 px-4 rounded-md font-bold inline-block"
    >
      Pay Now via CashApp
    </a>
    <p className="text-gray-400 mt-2 text-sm">Or copy the link below:</p>
    <div className="flex items-center justify-center gap-2">
      <p className="text-gray-300 text-xs break-words">{cashAppPayLink}</p>
      <button
        onClick={() => navigator.clipboard.writeText(cashAppPayLink)}
        className="text-[#FFDD15] hover:text-black focus:outline-none"
        title="Copy to clipboard"
      >
        <FaClipboard className="w-4 h-4" />
      </button>
    </div>
  </div>
)}

        {directPassPage ? (
          <>
            <div className="mb-4 overflow-x-auto">
              <table className="min-w-full bg-[#2D3748] text-white rounded-lg overflow-hidden">
                <thead className="bg-[#4A5568]">
                  <tr>
                    <th className="px-4 py-2 text-left">Name</th>
                    <th className="px-4 py-2 text-left">Amount</th>
                    <th className="px-4 py-2 text-left">Bonus</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-[#555]">
                    <td className="px-4 py-2 flex items-center">
                      <span>Wallet</span>
                    </td>
                    <td className="px-4 py-2 text-green-400">{finalTotal}</td>
                    <td className="px-4 py-2">No Bonus</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div className="mb-4 overflow-x-auto">
              <table className="min-w-full bg-[#2D3748] text-white rounded-lg overflow-hidden">
                <thead className="bg-[#4A5568]">
                  <tr>
                    <th className="px-4 py-2 text-left">Name</th>
                    <th className="px-4 py-2 text-left">Price</th>
                    <th className="px-4 py-2 text-left">Qty.</th>
                  </tr>
                </thead>
                <tbody>
                  {cartDataState && cartDataState.length > 0 ? (
                    cartDataState.map((item, index) => (
                      <tr key={index} className="border-b border-[#555]">
                        <td className="px-4 py-2 flex items-center">
                          <img
                            src={item.game_image}
                            alt={item.game_name}
                            className="w-10 h-10 rounded-md mr-2"
                          />
                          <span>{item.game_name}</span>
                        </td>
                        <td className="px-4 py-2 text-green-400">
                          ${parseFloat(item.game_price).toFixed(2)}
                        </td>
                        <td className="px-4 py-2">{item.quantity}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="px-4 py-2 text-center">
                        No items in cart
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        <div className="flex items-center justify-center border-t border-[#555] pt-4">
          <label
            htmlFor="upload"
            className="flex items-center text-white bg-[#222222] py-2 px-4 rounded-full cursor-pointer gap-2"
          >
            <img src={downloadIcon} alt="Upload" className="w-6 sm:w-7" />
            <span className="text-sm sm:text-lg">Upload Image</span>
          </label>
          <input
            type="file"
            id="upload"
            className="hidden"
            onChange={handleFileChange}
          />
        </div>
        {/* Error Display */}
        {error && <div className="text-red-500 text-sm mb-4">{error}</div>}

        <button
          onClick={handleSubmit}
          type="submit"
          disabled={!cashAppFile}
          className={`w-full py-2 rounded-md font-bold text-lg mt-6 ${cashAppFile
            ? "bg-[#FFDD15] text-black"
            : "bg-gray-400 text-gray-700 cursor-not-allowed"
            }`}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default CashAppPage;
