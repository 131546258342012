import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import WeeklyPopupBgImg from '../../assets/image/weeklyPopupBgImg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { toast } from 'react-toastify';

const TaskModal = ({ taskModalOpen, closeTaskModal, isLoggedIn }) => {
  const lastClickTimes = JSON.parse(localStorage.getItem('lastClickTimes')) || {};
  const token = useSelector((state) => state.auth.token);
  const userId = useSelector((state) => state.auth.user?.id);
  const [fingerprint, setFingerprint] = React.useState(null);

  useEffect(() => {
    FingerprintJS.load().then(fp => {
      fp.get().then(result => {
        setFingerprint(result.visitorId);
      });
    });
  }, []);

  const tasks = [
    { id: 1, action: 'facebook_share', reward: '$2', label: 'Share on Facebook', icon: faFacebook },
    { id: 2, action: 'instagram_post', reward: '$3', label: 'Post on Instagram', icon: faInstagram },
    { id: 3, action: 'twitter_tweet', reward: '$2', label: 'Tweet on Twitter', icon: faTwitter },
    { id: 4, action: 'pinterest_pin', reward: '$2', label: 'Pin on Pinterest', icon: faPinterest },
  ];

  const generateUniqueUrl = (userId, action) => {
    const timestamp = new Date().getTime(); // Current timestamp in milliseconds
    const firstEncodeUserId = btoa(userId);
    const reversedUserId = firstEncodeUserId.split('').reverse().join('');
    const doubleEncodedUserId = btoa(reversedUserId);
    const firstEncodeTimestamp = btoa(timestamp.toString());
    const reversedTimestamp = firstEncodeTimestamp.split('').reverse().join('');
    const doubleEncodedTimestamp = btoa(reversedTimestamp);

    return `https://luckycharmsweep.com/social-reward?user=${doubleEncodedUserId}&action=${action}&t=${doubleEncodedTimestamp}`;
};


  const handleSocialReward = async (action) => {
    if (!isLoggedIn) {
      toast.warn('Please sign in to complete this task.');
      return;
    }

    const uniqueUrl = generateUniqueUrl(userId, action);

    switch (action) {
      case 'facebook_share':
  
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(uniqueUrl)}&quote=Juwa - 100% Legit Payouts - Download Juwa - Lucky charm sweep\n\n100% Legit\nFast Payout and Deposit\n24/7 hours service\n#Luckycharmsweep`;
      window.open(facebookShareUrl, '_blank');
        
        break;

      case 'instagram_post':
        const instagramShareUrl = `https://www.instagram.com/?url=${encodeURIComponent(uniqueUrl)}`;
        window.open(instagramShareUrl, '_blank');
        break;

      case 'twitter_tweet':
        const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(`Check out this awesome site: ${uniqueUrl} #Luckycharmsweep`)}`;
        window.open(twitterShareUrl, '_blank');
        break;

      case 'pinterest_pin':
        const pinterestShareUrl = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(uniqueUrl)}&media=${encodeURIComponent('your-image-url')}&description=${encodeURIComponent('your-description #Luckycharmsweep')}`;
        window.open(pinterestShareUrl, '_blank');
        break;

      default:
        window.open(`https://luckycharmsweep.com/`, '_blank'); 
    }
  };

  // Facebook SDK initialization
  useEffect(() => {
    if (!window.FB) {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '1881219372287112', 
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v12.0' 
        });
      };
      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }
  }, []);

  if (!taskModalOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80 p-4">
      <div className="relative bg-gradient-to-br from-gray-800 via-black to-gray-900 text-white rounded-lg w-full max-w-md p-6 shadow-2xl">
        <button
          className="absolute top-3 right-3 text-white text-2xl"
          onClick={closeTaskModal}
        >
          &times;
        </button>

        <div
          className="flex justify-center items-center h-28 bg-cover bg-center rounded-t-lg w-full"
          style={{
            backgroundImage: `url(${WeeklyPopupBgImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderTopLeftRadius: '0.5rem',
            borderTopRightRadius: '0.5rem',
          }}
        >
          <h2 className="text-2xl font-bold text-yellow-400 tracking-wide">
            Earn Free Play
          </h2>
        </div>

        <p className="text-center text-white font-bold text-sm mt-2">
          Share on social media, then click the shared link to claim your reward!
        </p>

        <div className="mt-6 overflow-x-auto">
          <table className="w-full text-left border-separate border-spacing-y-3">
            <thead>
              <tr>
                <th className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-black font-semibold p-3 rounded-tl-lg">
                  Reward Condition
                </th>
                <th className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-black font-semibold p-3">
                  Freeplay
                </th>
                <th className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-black font-semibold p-3 rounded-tr-lg">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task) => (
                <tr key={task.id} className="bg-purple-600 text-center rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                  <td
                    className="flex items-center gap-2 p-3 justify-start rounded-lg border-l-4 border-yellow-500 pl-4"
                    style={{ borderRadius: '0.375rem' }}
                  >
                    <FontAwesomeIcon icon={task.icon} className="text-yellow-400 text-lg" />
                    <span>{task.label}</span>
                  </td>
                  <td className="p-3 text-yellow-300">{task.reward}</td>
                  <td className="p-3 rounded-r-lg">
                    <button
                      className="w-full max-w-[100px] bg-gradient-to-r from-yellow-500 to-yellow-400 hover:from-yellow-400 hover:to-yellow-500 text-black font-semibold py-2 rounded transition-transform transform hover:scale-105"
                      onClick={() => handleSocialReward(task.action)}
                    >
                      {task.label.split(' ')[0]} 
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TaskModal;