import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const SocialReward = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => !!state.auth.token); // Check for token existence

  useEffect(() => {
    console.log('Component Mounted'); // Debug log for component mount
    const encodedUserId = searchParams.get('user');
    const action = searchParams.get('action');
    const timestamp = searchParams.get('t');

    console.log('Query Parameters:', { encodedUserId, action, timestamp });

    // Validate query parameters
    if (!encodedUserId || !action || !timestamp) {
      console.warn('Missing query parameters'); // Debug log for missing parameters
      if (isLoggedIn) {
        toast.error('Invalid request.');
      }
      navigate('/home');
      return;
    }

    // Decoding logic
    try {
      const userId = atob(encodedUserId);
      const doubleEncodedUserId = searchParams.get('user');
      const doubleEncodedTimestamp = searchParams.get('t');
      const reversedUserId = atob(doubleEncodedUserId);
      const firstDecodeUserId = reversedUserId.split('').reverse().join('');
      const decodedUserId = atob(firstDecodeUserId);
      const reversedTimestamp = atob(doubleEncodedTimestamp);
      const firstDecodeTimestamp = reversedTimestamp.split('').reverse().join('');
      const decodedTimestamp = atob(firstDecodeTimestamp);

      console.log('Decoded Values:', { decodedUserId, decodedTimestamp });

      const fetchData = async () => {
        try {
          const apiUrl = `https://api.luckycharmsweep.com/api/v2/dfjfngjn/social-reward.php?user=${decodedUserId}&action=${action}&t=${decodedTimestamp}`;
          console.log('API URL:', apiUrl);

          const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          const responseText = await response.text();
          console.log('Raw API Response:', responseText); // Log the raw API response

          let responseData;
          try {
            responseData = JSON.parse(responseText.trim());
            console.log('Parsed API Response:', responseData);
          } catch (error) {
            console.error('Error parsing JSON:', error);
            console.log('Response Text:', responseText);
            if (isLoggedIn) {
              toast.error('An error occurred while processing the reward.');
            }
            return;
          }

          if (responseData.status && responseData.status.code === '1') {
            console.log('Reward successfully added.'); // Debug log for success
            if (isLoggedIn) {
              toast.success('Reward added successfully!');
            }
          } else {
            const errorMessage = responseData.status?.message || 'Failed to add reward. Please try again later.';
            console.warn('Reward failed:', errorMessage); // Debug log for failure
            if (isLoggedIn) {
              toast.error(errorMessage);
            }
          }
        } catch (error) {
          console.error('Error adding reward:', error);
          if (isLoggedIn) {
            toast.error('An error occurred.');
          }
        } finally {
          console.log('Navigating to home'); // Debug log for navigation
          navigate('/home');
        }
      };

      fetchData();
    } catch (decodeError) {
      console.error('Decoding error:', decodeError);
      if (isLoggedIn) {
        toast.error('Invalid encoding in request.');
      }
      navigate('/home');
    }
  }, [searchParams, navigate, isLoggedIn]);

  return null;
};

export default SocialReward;
