import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DownArrow2, UpArrow } from '../../utils/Icons';
import { addToCart, removeFromCart } from '../../redux/slice/gamesSlice';
import { slugify } from '../../utils/slugify'; // Helper function to create URL-friendly slugs

const MainContent = ({ depositItems, counters, setCounters, fetchPlatforms }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);

    const handleChange = (index, e) => {
        const newCounters = [...counters];
        newCounters[index] = e.target.value;
        setCounters(newCounters);
    };

    const increment = (index) => {
        const newCounters = [...counters];
        newCounters[index] = parseInt(newCounters[index]) + 1;
        setCounters(newCounters);
    };

    const decrement = (index) => {
        const newCounters = [...counters];
        newCounters[index] = Math.max(parseInt(newCounters[index]) - 1, 10);
        setCounters(newCounters);
    };

    const viewCart = () => {
        if (token) {
            navigate('/cart');
        } else {
            navigate('/login');
        }
    };

    const AddToCart = async (gameID, index) => {
        const quantity = counters[index];
        // Check if quantity is less than 10
  if (quantity < 10) {
    toast.error("Minimum deposit amount is $10.");
    return;
  }
        dispatch(addToCart({ id: gameID, quantity }));
        toast.success(
            <div className="flex items-center">
                <span>Add To Cart</span>
                <button
                    className='ml-auto text-[#FFDD15] border border-[#FFDD15] flex justify-center items-center gap-2 px-5 font-semibold py-1 rounded-md bg-transparent'
                    onClick={viewCart} // Redirect to cart page on click
                >
                    View Cart
                </button>
            </div>,
            {
                position: 'top-center',
                autoClose: 5000, // Auto close after 5 seconds
                theme: 'dark'
            }
        );
    };

    const RemoveFromCart = async (gameID) => {
        dispatch(removeFromCart({ id: gameID }));
        toast.success("Item removed from cart successfully!");
    };

    const platformDepositItems = depositItems.filter(item => item.tags === "platform");
        
    return (
        <div className='container mx-auto py-10'>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5 gap-y-16 px-5 sm:px-0'>
                {platformDepositItems.map((depositItem, index) => (
                    <div key={depositItem.id} className='rounded-3xl w-full h-[450px] flex flex-col'>
<div
  className="w-full overflow-hidden rounded-t-3xl border-t-2 border-r-2 border-l-2 border-[#FFDD15]"
  style={{
    background: 'linear-gradient(164deg, rgb(80 26 198) 0%, rgb(140 83 222) 100%)',
  }}
>
                            <img
                                src={depositItem.game_image}
                                alt="item"
                                className='w-full h-full object-contain'
                            />
                            
                        </div>
                        <div className='bg-[#222222] flex flex-col justify-between flex-grow gap-7 py-3 px-3 rounded-bl-2xl rounded-br-2xl border-b-[3px] border-[#FFDD15]'>
                            <div>
                                <p className='text-white font-semibold text-lg'>{depositItem.game_name}</p>
                                <p className='text-[#01D370] font-bold text-2xl'>${depositItem.game_price}</p>
                            </div>
                            {/* Category and Platform Tags */}
                            <div className="flex justify-start gap-2 mb-2">
                                {depositItem.category_name && (
                                    <Link
                                        to={`/games?category=${encodeURIComponent(depositItem.category_name)}`}
                                        className="bg-[#FFDD15] text-[#0E0E0E] text-xs rounded-full px-3 py-1 font-semibold"
                                    >
                                        {depositItem.category_name}
                                    </Link>
                                )}
                                {depositItem.platform_name && (
                                    <Link
                                        to={`/games?platform=${encodeURIComponent(depositItem.platform_name)}`}
                                        className="bg-[#FFDD15] text-[#0E0E0E] text-xs rounded-full px-3 py-1 font-semibold"
                                    >
                                        {depositItem.platform_name}
                                    </Link>
                                )}
                            </div>

                            <div className="flex justify-between items-center bg-[#0E0E0E] border border-white rounded w-20 h-8">
                                <input
                                    type="text"
                                    value={counters[index]}
                                    onChange={(e) => handleChange(index, e)}
                                    className="w-10 bg-[#0E0E0E] text-white text-center outline-none flex-1"
                                    disabled={depositItem.is_game_add} // Disable if game is already in cart
                                />
                                <div className="flex flex-col justify-center items-center gap-1 border-l flex-1">
                                    <UpArrow
                                        className={`w-4 cursor-pointer ${depositItem.is_game_add && 'opacity-50 cursor-not-allowed'}`}
                                        onClick={() => !depositItem.is_game_add && increment(index)}
                                    />
                                    <DownArrow2
                                        className={`w-4 cursor-pointer ${depositItem.is_game_add && 'opacity-50 cursor-not-allowed'}`}
                                        onClick={() => !depositItem.is_game_add && decrement(index)}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col gap-3'>
                                {depositItem.is_game_add ? (
                                    <button
                                        className='text-[#FFDD15] border border-[#FFDD15] flex justify-center items-center gap-2 px-5 font-semibold py-2 rounded-md bg-transparent'
                                        onClick={() => RemoveFromCart(depositItem.id, index)}
                                    >
                                        <span>Remove From Cart</span>
                                    </button>
                                ) : (
                                    <button
                                        className='bg-[#FFDD15] text-[#0E0E0E] flex justify-center items-center gap-2 px-5 font-semibold py-2 rounded-md'
                                        onClick={() => AddToCart(depositItem.id, index)}
                                    >
                                        <span>Add To Cart</span>
                                    </button>
                                )}
                               
                               <Link 
  to={depositItem.game_description ? `/platform/description/${slugify(depositItem.game_name)}` : '#'} 
  className='text-[#f3efefba] font-semibold text-sm text-center'
>
  Learn More
</Link>

                                     
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MainContent;
