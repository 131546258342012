import OneSignal from 'react-onesignal';

import React, { useEffect, useState } from 'react';
import { InfoIcon } from '../../utils/Icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import secure from '../../assets/image/secure.png';
import { toast } from 'react-toastify';
import Loading from '../../components/Common/Loading';
import { resetGamesState } from '../../redux/slice/gamesSlice';
import CheckoutNotFound from '../../components/Checkout/CheckoutNotFound';
import { ApiHandler } from '../../helper/ApiHandler';

const AmountDeposit = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const token = useSelector((state) => state.auth.token);
    const userData = useSelector((state) => state.auth.user);

    const cartData = location.state.cartDataState;
    console.log(cartData);


    const totalAmountState = location.state.totalAmountState;
    const totalAmount = location.state.totalAmount;
    const method = location.state.method;
    const discount = location.state.discount || '';
    const discountamount = location.state.discountamount || '';

    const totalDepositBonusAmount = location.state.totalDepositBonusAmount || '';
    const weeklyChallengeBonusAmount = location.state.weeklyChallengeBonusAmount || '';
    const finalTotal = +location.state.finalTotal || '';
    const promoCode = location.state.promoCode || '';

    const promoCodeData = location.state.promoCodeData || '';
    const BonusLevelAmount = location.state.BonusLevelAmount || '';
    const registrationBonusPercentage = location.state.registrationBonusPercentage || '';
    const [cryptoValue, setCryptoValue] = useState(null); // State to store crypto value

    const sendDepositRequest = async () => {
        try {
            setLoading(true);
            dispatch(resetGamesState());

            const amount = finalTotal;
            const totalAmount = totalAmountState;

            const response = await ApiHandler('/deposit_game.php', 'POST', {
                email: userData.email,
                phone: userData.phone,
                amount: amount ? parseFloat(amount) : '',
                promocode: promoCode,
                promocodeDiscount: promoCodeData.promoCodePercentage ? parseFloat(promoCodeData.promoCodePercentage) : '',
                discountamount: promoCodeData.promoCodeAmount ? parseFloat(promoCodeData.promoCodeAmount) : '',
                totalamount: totalAmount ? parseFloat(totalAmount) : '',
                payment_type: method,
                weekly_challenge_bonus: weeklyChallengeBonusAmount ? parseFloat(weeklyChallengeBonusAmount) : '',
                game_detail: cartData.map((game) => ({
                    game_name: game.game_name ? game.game_name : '',
                    game_id: game.id ? game.id : '',
                    price: game.game_price ? parseFloat(game.game_price) : '',
                    quantity: game.quantity ? parseFloat(game.quantity) : '',
                    total: parseFloat(game.quantity * (game.game_price || game.price)),
                    deposit_bonus: game.bonus ? parseFloat(game.bonus) : '',
                    platformsID: game.platforms_id ? game.platforms_id : '',
                })),
                account_level_bonus: BonusLevelAmount ? parseFloat(BonusLevelAmount) : '',
                totalDepositBonusAmount: totalDepositBonusAmount ? parseFloat(totalDepositBonusAmount) : '',
                registration_bonus_for_new_users: registrationBonusPercentage ? parseFloat(registrationBonusPercentage) : '',
            }, token, dispatch, navigate);

            if (response.data.status.code === 1) {
                OneSignal.User.addTags({
                    role: 'paid user', // Adding role as 'paid user'
                });
                if (response.data.data.checkoutLink) {
                    setLoading(false);
                    window.location.href = response.data.data.checkoutLink;
                } else {
                    toast.success("Deposit request submitted successfully! Your funds will be added shortly.");
                    navigate('/user/deposits');
                    setLoading(false);
                }
            } else {
                toast.error(response.data.status.message);
                setLoading(false);
            }
            setLoading(false);
        } catch (error) {
            console.error("API call error:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchCryptoValue = async () => {
            if (['BTC', 'LTC', 'DOGE'].includes(method)) {
                try {
                    const tickerMap = {
                        BTC: 'XXBTZUSD', // Kraken uses XXBT for Bitcoin
                        LTC: 'XLTCZUSD', // Kraken uses XLTC for Litecoin
                        DOGE: 'XDGUSDT', // Kraken uses XXDG for Dogecoin
                    };
    
                    const tickerName = tickerMap[method];
                    const response = await fetch(`https://api.kraken.com/0/public/Ticker?pair=${tickerName}`);
                    const data = await response.json();
    
                    if (data?.result?.[tickerName]?.a?.[0]) {

                        // Extract the ask price (current price) of the cryptocurrency in USD
                        const value = parseFloat(data.result[tickerName].a[0]);
                        
                        // Calculate the equivalent cryptocurrency amount
                        const cryptoAmount = parseFloat(finalTotal) / value;
    
                        // Set the crypto value (rounded to 8 decimal places)
                        setCryptoValue(cryptoAmount.toFixed(8));
                    } else {
                        console.error('Error: Ticker not found in API response:', tickerName, data);
                    }
                } catch (error) {
                    console.error('Error fetching crypto value:', error);
                }
            }
        };
    
        fetchCryptoValue();
    }, [method, finalTotal]);
    

    useEffect(() => {
        if (!location.state?.isCheckoutAllowed) {
            // If no state or invalid state, redirect to the home page
            navigate('/cart');
        } else {
            const currentState = window.history.state;
            const updatedState = {
                ...currentState,
                usr: {
                    ...currentState.usr,
                    isCheckoutAllowed: false
                }
            };

            // Replace the current history state with the updated state
            window.history.replaceState(updatedState, document.title);
        }
    }, [location, navigate]);

    if (!token) {
        navigate('/login');
        return;
    }

    if (!cartData || cartData.length === 0) {
        return (
            <CheckoutNotFound />
        );
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <div className="container mx-auto text-white mt-16 md:mt-36 px-4">
                  {/* Conditionally render the crypto instructions */}
      {(method === "BTC" || method === "LTC" || method === "DOGE") && (
        <div className="">
          <p className="mb-4">
            Please send the <span className="font-bold text-red-500 underline">exactly</span> <span className="font-bold">{cryptoValue}</span> <span>{method} </span> indicated amount of {method} to the wallet. {/* Display the selected crypto */}
          </p>
          <p className="mb-4">
            You can make the payment by manually entering the address or simply scanning the QR code with your {method} wallet. Once you issue payment, your order will be automatically processed.
          </p>

          <div className="text-white p-4 rounded mb-6 relative py-6 md:py-8"
            style={{ backgroundColor: method === "BTC" ? "#f7931a" : method === "LTC" ? "#345D9D" : "#C2A633" }}
          >
            <InfoIcon className="absolute top-2 left-2" />
            <p>
              If you do not send the exactly shown amount of {/* Display the crypto value */}
              {cryptoValue ? (
                <>
                  <span className="font-bold">{cryptoValue}</span> {method}
                </>
              ) : (
                "loading..." // Display loading message while fetching the value
              )}
              (not including the transaction fee), processing time for your order may take longer due to the manual review process.
            </p>
            <p className="font-bold mt-2">
              You may have to pay the missing part of your deposit after review.
            </p>
          </div>

          {/* ... your existing code ... */}
        </div>
      )}

                <hr className="border-white/15 border" />

                <div className="flex flex-col lg:flex-row w-full gap-5">
                    <div className="bg-gray-800 p-4 rounded my-6 flex-[2] h-full overflow-auto"
                        style={{
                            borderColor: 'rgba(255, 255, 255, 0.16)',
                            borderWidth: '1px'
                        }}
                    >
                        <div className="overflow-x-auto">
                            <table className="min-w-full table-auto">
                                <thead>
                                    <tr className='text-base md:text-lg font-semibold'>
                                        <th className="p-2 text-start">NAME</th>
                                        <th className="p-2 text-start">PRICE</th>
                                        <th className="p-2 text-start">QUANTITY</th>
                                        <th className="p-2 text-start">TOTAL</th>
                                        <th className="p-2 text-start">AMOUNT</th>
                                        <th className="p-2 text-start">BONUS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartData?.map((game, index) => {
                                        const gamePrice = (game.quantity * parseFloat(game.game_price))
                                        return (
                                            <tr key={index} className="border-b border-gray-700 text-sm md:text-base font-semibold md:font-medium">
                                                <td className="border px-2 py-4">{game.game_name}</td>
                                                <td className="border px-2 py-4">${parseFloat(game.game_price).toFixed(2)}</td>
                                                <td className="border px-2 py-4">{game.quantity}</td>
                                                <td className="border px-2 py-4">${gamePrice}</td>
                                                <td className="border px-2 py-4">
                                                    {
                                                        game.bonus ?
                                                            `$${(gamePrice + parseFloat(game.bonus)).toFixed(2)}`
                                                            : `$${(gamePrice).toFixed(2)}`
                                                    }
                                                </td>
                                                <td className="border px-2 py-4">
                                                    {game.bonus ? `${game.bonus} bonus` : `No bonus`}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="bg-gray-800 p-4 rounded my-6 flex-1 h-auto"
                        style={{
                            borderColor: 'rgba(255, 255, 255, 0.16)',
                            borderWidth: '1px'
                        }}>
                        <button type="button"
                            className="w-full chakra-button css-ct8v74 !text-white"
                            style={{ backgroundColor: method === "BTC" ? "#f7931a" : method === "LTC" ? "#345D9D" : method === "DOGE" ? "#C2A633" : method === "Wallet" ? "#8cc43e" : "#ff5f66" }}
                            onClick={sendDepositRequest}
                        >
                            Pay with {method === "BTC" ? "Bitcoin" : method === "LTC" ? "Litecoin" : method === "DOGE" ? "Dogecoin" : method === "Wallet" ? "Wallet" : "Bonus"}
                        </button>
                        <p className='text-sm font-medium text-white mt-1'>
                            By placing this order, you agree with our
                            <Link to="/privacy-policy" className='text-blue-400 pl-1 pr-1 underline'>
                                Privacy Policy</Link>
                            and
                            <Link to="/terms-of-service" className='text-blue-400 pl-1 pr-1 underline'>
                                Terms Of Use
                            </Link>
                        </p>

                        <div className='my-4'>
                            <img src={secure} alt="Secure Payment" />
                        </div>

                        <h2 className="font-bold text-lg">Bonuses & Discounts</h2>
                        {totalAmount && totalAmount > 0 ? (
                            <div className="mb-2 flex justify-between font-bold">
                                <p>Amount</p>
                                <p>${totalAmount ? parseFloat(totalAmount).toFixed(2) : ''}</p>
                            </div>
                        ) : ""}
                        <hr className="border-white/5 border my-3" />
                        {registrationBonusPercentage && registrationBonusPercentage > 0 ? (
                            <div className="mb-2 flex justify-between font-bold">
                                <p>User First time Deposit Add Bounes</p>
                                <p>+${registrationBonusPercentage ? parseFloat(registrationBonusPercentage).toFixed(2) : ''}</p>
                            </div>
                        ) : ""}
                        {totalDepositBonusAmount ? (
                            <div className="mb-2 flex justify-between font-bold">
                                <p>Deposit Bonus</p>
                                <p>+${totalDepositBonusAmount ? parseFloat(totalDepositBonusAmount).toFixed(2) : ''}</p>
                            </div>
                        ) : ""}
                        {weeklyChallengeBonusAmount ? (
                            <div className="mb-2 flex justify-between font-bold">
                                <p>Weekly Challenge Bonus</p>
                                <p>+${weeklyChallengeBonusAmount ? parseFloat(weeklyChallengeBonusAmount).toFixed(2) : ''}</p>
                            </div>
                        ) : ""}
                        {BonusLevelAmount ? (
                            <div className="mb-2 flex justify-between font-bold">
                                <p>Bonus Level</p>
                                <p>+${BonusLevelAmount ? parseFloat(BonusLevelAmount).toFixed(2) : ''}</p>
                            </div>
                        ) : ""}
                        {promoCode ? (
                            <div className="mb-2 flex justify-between items-center font-bold">
                                <p>Promo Code</p>
                                <span className="text-sm text-white bg-blue-500 px-2 py-1 rounded-md">
                                    {promoCode}
                                </span>
                            </div>
                        ) : ""}

                        {discount ? (
                            <div className="mb-2 flex justify-between font-bold">
                                <p>Promo Code Discount</p>
                                <p>{discount && !isNaN(discount) ? `${(parseFloat(discount) * 100).toFixed(2)}%` : ''}</p>
                            </div>
                        ) : null}
                        {promoCode ? (
                            <div className="mb-2 flex justify-between items-center font-bold">
                                <p>PromoCode total discount amount</p>
                                <p>-${discountamount ? parseFloat(discountamount).toFixed(2) : ''}</p>
                            </div>
                        ) : ""}
                        {totalAmountState ? (
                            <div className="mb-2 flex justify-between font-bold">
                                <p>Original Total</p>
                                <p>${totalAmountState ? parseFloat(totalAmountState).toFixed(2) : ''}</p>
                            </div>
                        ) : ""}

                        <hr className="border-white/5 border my-5" />

                        {finalTotal ? (
                            <div className="mt-4 flex justify-between">
                                <p className="font-bold">Total Amount</p>
                                <p>${finalTotal ? parseFloat(finalTotal)?.toFixed(2) : ''}</p>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AmountDeposit;
