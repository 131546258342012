import React from 'react';
import { Link } from 'react-router-dom';

const ReferralProgram = () => {
    return (
        <div className="flex flex-col min-h-screen bg-[#131A2A] p-4 md:p-8 md:py-36">
            <div className="flex flex-col md:flex-row items-center md:space-x-8 bg-[#1F2937] p-6 sm:p-8 rounded-lg shadow-lg text-white">
            <div className="w-full md:w-1/2 flex justify-center mb-6 md:mb-0">
                    <img
                        src='https://ik.imagekit.io/luckycharm/3565_Refer_a_Friend_2024_Hero_Article_Banner_Overlay_600x600px_5203edd9a2.png?updatedAt=1731174403680'
                        alt="Referral Program"
                        className="w-full max-w-xs xs:max-w-sm sm:max-w-md md:max-w-lg"
                    />
                </div>

                {/* Text Section */}
                <div className="w-full md:w-1/2">
                    <h2 className="text-2xl xs:text-3xl sm:text-4xl font-bold mb-4 text-center">🎉 Refer Friends & Win Big! 🎉</h2>
                    
                    <div className="bg-gradient-to-r from-purple-500 to-pink-500 p-4 rounded-lg mb-4"> 
                        <p className="mb-4 text-lg font-semibold text-white">For every friend you refer who deposits, YOU BOTH get $10!</p> 
                        <p className="mb-4">Plus, earn 10% of their bonus on EVERY deposit they make - FOREVER!</p> 
                    </div>

                    <p className="mb-4">
                        It's easy! Just head to your <span className="font-bold">Profile Tab</span>, grab your unique referral code, and share it with your friends. 
                    </p>

                    <p className="mb-4">
                        Invite 10 friends and earn a total of <span className="text-yellow-300 font-bold">$100 plus 10 Free Spins!</span> {/* Updated line */}
                    </p>


                    <Link
                        to="/user/referrals"
                        className="inline-block bg-[#EC29FC] text-white py-2 px-4 rounded-full text-center hover:bg-[#C713D1] font-bold mt-4" 
                    >
                        🚀 Get Your Referral Code Now! 🚀 
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ReferralProgram;
