import { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

const SubscriptionPage = () => {
  const token = useSelector((state) => state.auth.token);
  const userID = useSelector((state) => state.auth.userID); // Assuming `userID` is stored in Redux
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const handleSubscriptionChoose = (planId) => {
    if (!token) {
      toast.warn("Sign in required!");
      return;
    }
    setSelectedPlanId(planId);
  };

  const initialOptions = {
    "client-id": "ASgp49YkCvxro7D1Mlvg2a_2k1Fy8bDi_Dn-peYz2iU1eoU0OxCpj1Pw8R3oSqKkXEDwh2NgllH0IQ80",
    vault: true,
    intent: "subscription",
    components: "buttons",
  };

  const handleSubscriptionCapture = async (subscriptionID) => {
    try {
      const response = await fetch("/capture_subscription.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          subscriptionID,
          userID, // Pass the logged-in user's ID
        }),
      });

      const data = await response.json();
      if (data.success) {
        toast.success("Subscription captured successfully!");
      } else {
        toast.error(`Failed to capture subscription: ${data.message}`);
      }
    } catch (error) {
      console.error("Error capturing subscription:", error);
      toast.error("An error occurred while capturing the subscription.");
    }
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div className="flex flex-col min-h-screen bg-[#131A2A] p-4 md:p-8 md:py-36">
        <div className="bg-[#1F2937] p-6 sm:p-8 rounded-lg shadow-lg text-white">
          <h1 className="text-3xl sm:text-4xl font-bold mb-6 text-center">
            Level Up Your Weekend with Our Exclusive Subscription!
          </h1>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Beginner Plan */}
            <div className="bg-[#272727] p-6 rounded-lg transition-transform duration-200 hover:scale-105">
              <h2 className="text-2xl font-bold mb-4">Beginner</h2>
              <p className="text-lg mb-4">$9.99 / month</p>
              <ul className="pl-6 text-base mb-4">
                <li>
                  <CheckIcon className="w-5 h-5 text-green-500 inline-block mr-2" />
                  FREE PLAY every weekend (Sat & Sun)
                </li>
                <li>
                  <CheckIcon className="w-5 h-5 text-green-500 inline-block mr-2" />
                  25% of winnings
                </li>
                <li>
                  <CheckIcon className="w-5 h-5 text-green-500 inline-block mr-2" />
                  Access to 1 platform
                </li>
                <li>
                  <XMarkIcon className="w-5 h-5 text-red-500 inline-block mr-2" />
                  Hot High Winning Platform Alerts
                </li>
              </ul>

              {selectedPlanId === "P-92V89647419774118M45IIOY" ? (
                <PayPalButtons
                  style={{ layout: "vertical", label: "subscribe", color: "gold", shape: "rect" }}
                  createSubscription={(data, actions) => {
                    return actions.subscription.create({
                      plan_id: selectedPlanId,
                    });
                  }}
                  onApprove={(data) => {
                    toast.success(`Subscription successful! ID: ${data.subscriptionID}`);
                    handleSubscriptionCapture(data.subscriptionID);
                  }}
                  onError={(err) => {
                    toast.error(`Error: ${err.message}`);
                  }}
                />
              ) : (
                <button
                  className="bg-[#FFD700] hover:bg-[#DAA520] text-black font-bold py-2 px-4 rounded-md w-full transition-colors duration-200"
                  onClick={() => handleSubscriptionChoose("P-92V89647419774118M45IIOY")}
                >
                  Choose Beginner
                </button>
              )}
            </div>

            {/* Pro Plan */}
            <div className="bg-[#272727] p-6 rounded-lg relative transition-transform duration-200 hover:scale-105">
              <span className="absolute top-2 right-2 px-2 py-1 bg-blue-500 text-white text-xs rounded">
                Most Popular
              </span>
              <h2 className="text-2xl font-bold mb-4">Pro</h2>
              <p className="text-lg mb-4">$29.99 / month</p>
              <ul className="pl-6 text-base mb-4">
                <li>
                  <CheckIcon className="w-5 h-5 text-green-500 inline-block mr-2" />
                  FREE PLAY every weekend (Sat & Sun)
                </li>
                <li>
                  <CheckIcon className="w-5 h-5 text-green-500 inline-block mr-2" />
                  50% of winnings
                </li>
                <li>
                  <CheckIcon className="w-5 h-5 text-green-500 inline-block mr-2" />
                  Access to 3 platforms
                </li>
                <li>
                  <CheckIcon className="w-5 h-5 text-green-500 inline-block mr-2" />
                  Hot High Winning Platform Alerts
                </li>
              </ul>

              {selectedPlanId === "P-2KF235974U2231139M5BCAPI" ? (
                <PayPalButtons
                  style={{ layout: "vertical", label: "subscribe", color: "gold", shape: "rect" }}
                  createSubscription={(data, actions) => {
                    return actions.subscription.create({
                      plan_id: selectedPlanId,
                    });
                  }}
                  onApprove={(data) => {
                    toast.success(`Subscription successful! ID: ${data.subscriptionID}`);
                  }}
                  onError={(err) => {
                    toast.error(`Error: ${err.message}`);
                  }}
                />
              ) : (
                <button
                  className="bg-[#FFD700] hover:bg-[#DAA520] text-black font-bold py-2 px-4 rounded-md w-full transition-colors duration-200"
                  onClick={() => handleSubscriptionChoose("P-2KF235974U2231139M5BCAPI")}
                >
                  Choose Pro
                </button>
              )}
            </div>

            {/* Expert Plan */}
            <div className="bg-[#272727] p-6 rounded-lg transition-transform duration-200 hover:scale-105">
              <h2 className="text-2xl font-bold mb-4">Expert</h2>
              <p className="text-lg mb-4">$49.99 / month</p>
              <ul className="pl-6 text-base mb-4">
                <li>
                  <CheckIcon className="w-5 h-5 text-green-500 inline-block mr-2" />
                  FREE PLAY every weekend (Sat & Sun)
                </li>
                <li>
                  <CheckIcon className="w-5 h-5 text-green-500 inline-block mr-2" />
                  75% of winnings
                </li>
                <li>
                  <CheckIcon className="w-5 h-5 text-green-500 inline-block mr-2" />
                  Access to all 5 platforms
                </li>
                <li>
                  <CheckIcon className="w-5 h-5 text-green-500 inline-block mr-2" />
                  Hot High Winning Platform Alerts
                </li>
              </ul>

              {selectedPlanId === "P-1C368124AF191452TM5BCCBY" ? (
                <PayPalButtons
                  style={{ layout: "vertical", label: "subscribe", color: "gold", shape: "rect" }}
                  createSubscription={(data, actions) => {
                    return actions.subscription.create({
                      plan_id: selectedPlanId,
                    });
                  }}
                  onApprove={(data) => {
                    toast.success(`Subscription successful! ID: ${data.subscriptionID}`);
                  }}
                  onError={(err) => {
                    toast.error(`Error: ${err.message}`);
                  }}
                />
              ) : (
                <button
                  className="bg-[#FFD700] hover:bg-[#DAA520] text-black font-bold py-2 px-4 rounded-md w-full transition-colors duration-200"
                  onClick={() => handleSubscriptionChoose("P-1C368124AF191452TM5BCCBY")}
                >
                  Choose Expert
                </button>
              )}
            </div>
          </div>

          {/* FAQ Section */}
          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">Frequently Asked Questions</h2>
            <div className="space-y-4">
              {faqData.map((faq, index) => (
                <Disclosure key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#272727] px-4 py-3 text-left text-white hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <span className="font-bold">{faq.question}</span>
                        <ChevronUpIcon
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-purple-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-[#cbd5e1]">
                        {faq.answer}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
};

const faqData = [
  {
    question: "What are the benefits of subscribing?",
    answer:
      "Subscribing unlocks a world of benefits, including FREE PLAY every weekend, a higher percentage of winnings, access to more platforms, and exclusive features like Hot Platform Alerts.",
  },
  {
    question: "How does the FREE PLAY work?",
    answer:
      "Every Saturday and Sunday, you'll receive free credits to play on your chosen platform(s). You can use these credits to enjoy any of the available games.",
  },
  {
    question: "Can I upgrade my subscription plan?",
    answer: "Yes, you can upgrade your subscription plan anytime from your account settings.",
  },
  {
    question: "What happens if I cancel my subscription?",
    answer:
      "If you cancel your subscription, you'll lose access to subscription benefits at the end of your billing period.",
  },
  {
    question: "Do you offer any discounts?",
    answer: "Yes! We occasionally offer promotional discounts. Check the platform for details.",
  },
  {
    question: "What does 25% winnings mean?",
    answer:
      "25% winnings means that if you win a game using the Free Play balance provided by the Beginner plan, you will receive only 25% of your total winnings. The remaining 75% will belong to us. For example, if you win $100 using the Free Play balance, you will get $25, and $75 will remain with us. If you play using your own balance, 100% of your winnings will belong to you.",
  },
  {
    question: "What does 50% winnings mean?",
    answer:
      "50% winnings means that if you win a game using the Free Play balance provided by the Pro plan, you will receive 50% of your total winnings. The other 50% will belong to us. For example, if you win $100 using the Free Play balance, you will get $50, and $50 will remain with us. If you play using your own balance, 100% of your winnings will belong to you.",
  },
  {
    question: "What does 75% winnings mean?",
    answer:
      "75% winnings means that if you win a game using the Free Play balance provided by the Expert plan, you will receive 75% of your total winnings. The remaining 25% will belong to us. For example, if you win $100 using the Free Play balance, you will get $75, and $25 will remain with us. If you play using your own balance, 100% of your winnings will belong to you.",
  },
  {
    question: "What are Hot High Winning Platform Alerts?",
    answer:
      "Hot High Winning Platform Alerts provide you with real-time information about platforms that currently have a high winning probability based on our extensive user statistics and winning ratios. With our large user base, we analyze data to recommend platforms where your chances of winning are higher.",
  },
  
  
];

export default SubscriptionPage;
