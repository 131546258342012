import React, { useState, useEffect, useRef } from "react";
import HeroBox from "../HeroBox";
import { getImageUrl } from "../../utils/getImageUrl"; // Use helper function for background images
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiHandler } from "../../helper/ApiHandler";

const Index = () => {
    const [recentWinners, setRecentWinners] = useState([]);
    const [sliderImages, setSliderImages] = useState([]);
    const [loadingWinners, setLoadingWinners] = useState(true);
    const [loadingSlider, setLoadingSlider] = useState(true);
    const [firstImageLoaded, setFirstImageLoaded] = useState(false);
    const [isBgLoaded, setIsBgLoaded] = useState(false); // State to track background image loading
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const divRef = useRef(null); // Reference for background image lazy loading

    const backgroundImageUrl = getImageUrl('/assets/image/hero-bg.png');

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: firstImageLoaded,
        autoplaySpeed: 3000,
        arrows: false,
    };

    useEffect(() => {
        const fetchRecentWinners = async () => {
            try {
                const response = await ApiHandler(
                    "/get_recent_winner.php",
                    "POST",
                    undefined,
                    undefined,
                    dispatch,
                    navigate
                );
                setRecentWinners(response.data.data);
                setLoadingWinners(false);
            } catch (error) {
                console.error("Error fetching recent winners:", error);
                setLoadingWinners(false);
            }
        };

        const fetchSliderImages = async () => {
            try {
                const response = await ApiHandler(
                    "/get_homepage_slider_image.php",
                    "POST",
                    undefined,
                    token,
                    dispatch,
                    navigate
                );
                setSliderImages(response.data.data);
                setLoadingSlider(false);
            } catch (error) {
                console.error("Error fetching slider images:", error);
                setLoadingSlider(false);
            }
        };

        fetchRecentWinners();
        fetchSliderImages();
    }, [token, dispatch, navigate]);

    // Use IntersectionObserver to implement lazy loading for the background image
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsBgLoaded(true); // Load background image when in view
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        if (divRef.current) {
            observer.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                observer.disconnect();
            }
        };
    }, []);

    const handleImageLoad = (index) => {
        if (index === 0) {
            setFirstImageLoaded(true);
        }
    };

    return (
        <div
            ref={divRef} // Attach ref for lazy loading
            style={{
                backgroundImage: isBgLoaded ? `url(${backgroundImageUrl})` : 'none', // Load background image only when in view
            }}
            className="flex items-center justify-center min-h-screen bg-cover w-full bg-top"
        >
            <div className="flex gap-24 lgs:gap-16 flex-col lgs:flex-row mt-6">
            <HeroBox title={"Get $20 FREE BONUS on first order!"}> 
            {loadingSlider ? (
                        <div>Loading slider images...</div>
                    ) : (
                        <Slider {...sliderSettings} className="custom-slick-slider">
                            {sliderImages.map((imageData, index) => (
                                <div
                                    key={index}
                                    style={{
                                        width: "100%",
                                        height: "370px",
                                        overflow: "hidden",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        className="h-[370px] w-[100%] object-cover rounded-2xl"
                                        src={imageData.image}
                                        alt={`hero-${index + 1}`}
                                        onLoad={() => handleImageLoad(index)}
                                        loading="lazy" // Add lazy loading
                                        style={{
                                            width: "100%",
                                            objectFit: "cover",
                                            display: "block",
                                        }}
                                    />
                                </div>
                            ))}
                        </Slider>
                    )}
                </HeroBox>

                <HeroBox title={"Recent Winners"}>
                    <div className="infinite-scroll">
                        <div className="infinite-scroll-content w-full">
                            {loadingWinners ? (
                                <div>Loading...</div>
                            ) : recentWinners.length > 0 ? (
                                [...recentWinners, ...recentWinners].map((winner, index) => (
                                    <div
                                        key={`${winner.id}-${index}`}
                                        className="flex bg-[#222222] justify-between px-4 pb-2 w-full items-center"
                                    >
                                        <span className="winner-email bg-[#ffffff] bg-opacity-20 text-white h-fit px-1 py-1 rounded-sm text-xs sm:text-base">
                                            {winner.email}
                                        </span>
                                        <span className="winner-amount bg-[#ffffff] bg-opacity-20 text-white h-fit px-5 py-1 font-semibold rounded-sm text-xs sm:text-base">
                                            ${winner.amount}
                                        </span>
                                        <img
                                            src={winner.game_image}
                                            alt={winner.game_name}
                                            className="winner-image rounded-full object-cover"
                                            loading="lazy" // Add lazy loading
                                        />
                                    </div>
                                ))
                            ) : (
                                <div>No recent winners found</div>
                            )}
                        </div>
                    </div>
                </HeroBox>
            </div>
        </div>
    );
};

export default Index;
