import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Common/Loading";
import { useDispatch, useSelector } from "react-redux";
import { ApiHandler } from "../../../helper/ApiHandler";
import DataTable from 'react-data-table-component'; // Import DataTable
import { format } from 'date-fns';

const FreePlayForm = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [platform, setPlatform] = useState("");
  const [applyStatus, setApplyStatus] = useState(null);
  const [games, setGames] = useState([]);
  const [couponBalance, setCouponBalance] = useState(""); // State for coupon balance
  const [loading, setLoading] = useState(false);
  const [showRedeemSuccessPopup, setShowRedeemSuccessPopup] = useState(false);
  const [freeplayRequests, setFreeplayRequests] = useState([]); // State to store freeplay requests

  // Fetch games list
  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await ApiHandler(
          '/get_game_list.php',
          'POST',
          { gameID: "", filter: "platform", is_available: "1" },
          undefined,
          dispatch,
          navigate
        );
        setGames(response.data.data);
      } catch (error) {
        console.error("Error fetching game list:", error);
        toast.error("Failed to load game list. Please try again.");
      }
    };

    fetchGames();
  }, [dispatch, navigate]);

  // Fetch freeplay requests
  useEffect(() => {
    const fetchFreeplayRequests = async () => {
      try {
        const response = await ApiHandler('/get_freeplay_requests.php', 'GET', null, token);
        console.log("API Response:", response); 
  
        if (response?.data?.status?.code === 1 && response?.data?.data) {
          setFreeplayRequests(response.data.data); 
        } else if (response?.data?.status?.code === 0 && response?.data?.status?.message === "No freeplay requests found for this user.") {
          // Do nothing, or you could optionally set an empty array:
          setFreeplayRequests([]); 
        } else {
          // For other errors, show the toast
          console.error("Error fetching freeplay requests:", response?.data?.status?.message);
          toast.error("Failed to fetch freeplay requests.");
        }
      } catch (error) {
        console.error("Error fetching freeplay requests:", error);
        toast.error("Failed to fetch freeplay requests.");
      }
    };
  
    fetchFreeplayRequests();
  }, [token]);

  // State to track if the coupon code has been applied
  const [isCodeApplied, setIsCodeApplied] = useState(false);

  // Handle applying the coupon code
  const handleApplyCode = async () => {
    try {
      const response = await ApiHandler(
        '/verify_coupons_code.php',
        'POST',
        { coupons_code: code },
        token,
        dispatch,
        navigate
      );

      if (response?.data?.status?.code === 1) {
        toast.success("Great news! Your coupon code has been applied. Now, select the platform to complete your deposit.");
        setApplyStatus("Code applied successfully!");
        setCouponBalance(response.data.data.coupon_balance); // Set coupon balance
        setIsCodeApplied(true); // Set isCodeApplied to true after successful application
      } else {
        const errorMessage = response?.data?.status?.message || "Error applying the code.";
        setApplyStatus(errorMessage);
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error applying code:", error);
      setApplyStatus("Error applying the code. Please try again.");
      toast.error("Error applying the code. Please try again.");
    }
  };

  // Columns for the DataTable
  const columns = [
    { name: 'Game', selector: row => row.game_name, sortable: true },
    { name: 'Coupon', selector: row => row.coupon_code, sortable: true },
    { 
      name: 'Date',
      selector: row => format(new Date(row.created_date), 'yyyy-MM-dd HH:mm:ss'),
      sortable: true
    },
    { 
      name: 'Status', 
      selector: row => {
        const statusMap = {
          "Pending": <span className="text-yellow-500">Pending</span>,
          "Approved": <span className="text-green-500">Approved</span>,
          "Rejected": <span className="text-red-500">Rejected</span>,
          "Expired": <span className="text-gray-500">Expired</span>
        };
        return statusMap[row.payment_status] || <span className="text-gray-500">Unknown</span>;
      },
      sortable: true 
    },
    { 
      name: 'Balance Type', 
      selector: row => row.balance_type, 
      sortable: true
    }
  ];

  // Handle form submission for applying bonus
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!platform) {
      toast.error("Please select a platform before submitting.");
      return;
    }
    try {
      setLoading(true);

      const response = await ApiHandler(
        '/free_play_coupon_bonus.php',
        'POST',
        {
          coupons_code: code,
          coupon_balance: couponBalance, // Include coupon balance in the payload
          platform: platform,
          game_id: platform,
        },
        token,
        dispatch,
        navigate
      );

      if (response?.data?.status?.code === 1) {
        toast.success("Bonus applied successfully!");
        setShowRedeemSuccessPopup(true); // Show the popup on success
      } else {
        const errorMessage = response?.data?.status?.message || "Error applying the bonus. Please try again.";
        setApplyStatus(errorMessage);
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setApplyStatus("Error submitting the form. Please try again.");
      toast.error("Error submitting the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#290A47', // background color
        color: '#FFFFFF',           // text color
        fontSize: '16px',           // font size
        fontWeight: '600',          // font weight
        textAlign: 'start',         // text alignment
        padding: '10px',            // padding
      },
    },
    rows: {
      style: {
        backgroundColor: '#0E0E0E',
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: '400'
      },
    },
    pagination: {
      style: {
        backgroundColor: '#222222',
        color: '#FFFFFF',
      },
    },
    noData: {
      style: {
        padding: '20px',
        textAlign: 'center',
        backgroundColor: '#0E0E0E',
        color: '#FFFFFF',
        fontSize: '16px',
      },
    },
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="p-4 main-dot-bg text-white rounded-lg shadow-lg   justify-center"      >
        <div className="">
          {/* Display coupon balance after applying the code */}
          {couponBalance && (
            <div className="mb-4 bg-[#290A47] p-3 rounded-md text-yellow-400 text-center font-semibold">
              Coupon Balance: {couponBalance}
            </div>
          )}

          <div className="mb-4">
            <label htmlFor="code" className="block text-white mb-2 font-semibold">
              FREE-PLAY CODE *
            </label>
            <div className="flex flex-col sm:flex-row">
              <input
                type="text"
                id="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="CODE"
                required
                className="flex-grow p-2 mb-2 sm:mb-0 sm:mr-2 rounded-md bg-[#0E0E0E] main-border text-sm text-white placeholder:text-white focus:outline-none outline-none"
              />
              <button
                type="button"
                onClick={handleApplyCode}
                className="p-2 bg-[#290A47] text-white rounded-md font-semibold text-base"
                style={{ borderWidth: "1px", borderColor: "rgba(255, 255, 255, 0.16)" }}
                disabled={isCodeApplied} // Disable the button if the code is already applied
              >
                Apply code
              </button>
            </div>
            {applyStatus && (
              <p className={`mt-2 text-sm ${applyStatus.includes("successfully") ? "text-green-400" : "text-red-400"}`}>
                {applyStatus}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="platform" className="block text-white mb-2 font-semibold">
              Platform *
            </label>
            <select
              id="platform"
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
              required
              className="w-full p-3 mb-2 rounded-md bg-[#0E0E0E] main-border text-sm text-white placeholder:text-white focus:outline-none outline-none"
            >
              <option value="">Select Game</option>
              {games.map((game) => (
                <option key={game.id} value={game.id}>
                  {game.game_name}
                </option>
              ))}
              <option value="bonus">Bonus</option>
            </select>
          </div>

          <button
  type="submit"
  className={`w-40 border border-[#FFDD15] p-2 mt-3 font-semibold text-base rounded-md mx-auto block ${
    platform
      ? "bg-[#FFDD15] text-black"
      : "bg-gray-500 text-gray-300 cursor-not-allowed"
  }`}
  disabled={!platform}
>
  Submit
</button>
          <br />
          <div className="mb-4 text-center">
            <p className="text-sm text-gray-400">
              Please note: The $5 Freeplay bonus is currently only available for the USA users.
            </p>
          </div>
        </div>
      </form>

      {/* Freeplay Requests Table */}
      <div className="p-4 mt-4 main-dot-bg text-white rounded-lg shadow-lg ">
        <h2 className="text-xl font-semibold mb-4 text-white">Your Freeplay Requests</h2>
        <DataTable
          columns={columns}
          data={freeplayRequests}
          customStyles={customStyles}
          pagination
          responsive
          striped
          className="text-white" // Add class for white text
        />
      </div>
      

      {showRedeemSuccessPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80 p-4">
          <div className="relative bg-gradient-to-br from-gray-800 via-black to-gray-900 text-white rounded-lg w-full max-w-md p-6 shadow-2xl">
            <button
              onClick={() => {
                setShowRedeemSuccessPopup(false);
                navigate("/bonuses/level");
              }}
              className="absolute top-3 right-3 text-white text-2xl hover:text-gray-300"
            >
              &times;
            </button>

            <div className="flex items-center justify-center mb-4">
              <div className="w-20 h-20 rounded-full bg-white bg-opacity-20 flex items-center justify-center">
                <span role="img" aria-label="success" className="text-4xl">
                  ✅
                </span>
              </div>
            </div>

            <h2 className="text-2xl font-bold mb-4 text-center">Freeplay Redeemed!</h2>
            <p className="text-lg mb-4 text-center">
              Your request has been processed successfully. You will receive your platform credentials and balance shortly within 10-15 minutes.
            </p>
            <p className="text-center mb-4">Please be patient.</p>

            <button
              onClick={() => {
                setShowRedeemSuccessPopup(false);
                navigate("/bonuses/level");
              }}
              className="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded-md w-full transition-colors duration-200"
            >
              Okay
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FreePlayForm;
